export const BASE_API = process.env.REACT_APP_BASE_API;
export const LIVE_STATIC_HOST = process.env.REACT_APP_LIVE_STATIC_HOST || 'https://static.smarttraffic.app';
// export const BASE_API = 'http://localhost:3455/api'

export const LOGO = 'Smart Traffic';

export const SOCKET = {
    host: process.env.REACT_APP_SOCKET_HOST,
    // host:'ws://localhost:3222',
};

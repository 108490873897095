import request from 'src/utils/request';

const ShortLink = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/short_link', { params });
        return data;
    },
    async show(id) {
        return await request.get(`/admin/short_link/${id}`);
    },
    async update(id, data) {
        delete data.id;
        return await request.postForm(`/admin/short_link/${id}`, data);
    },
    async create(data) {
        return await request.postForm(`/admin/short_link`, data);
    },
    async delete(id) {
        return await request.delete(`/admin/short_link/${id}`);
    },
    async updateLinks(data) {
        return await request.post(`/admin/short_link/update_links`,data);
    },
};

export default ShortLink;

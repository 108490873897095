import request from 'src/utils/request';

const StatisticService = {
    async getCurrent() {
        return await request.get('admin/statistic/current');
    },
    async getToday() {
        return await request.get('admin/statistic/today');
    },
    async getThisWeek() {
        return await request.get('admin/statistic/this_week');
    },
    async getThisMonth() {
        return await request.get('admin/statistic/this_month');
    },
    async getTopOnline() {
        return await request.get('admin/statistic/top_online');
    },
    async getTopViewToDay() {
        return await request.get('admin/statistic/top_view_today');
    },
    async getTopViewThisMonth() {
        return await request.get('admin/statistic/top_view_this_month');
    },
    async byDate(params = {}) {
        return await request.get('admin/statistic/by_date', { params });
    },
    async byAdmin(params = {}) {
        return await request.get('admin/statistic/by_admin', { params });
    },
    async detailAdmin(id, params = {}) {
        return await request.get(`admin/statistic/by_admin/${id}`, { params });
    },
    async byCountry(params = {}) {
        return await request.get('admin/statistic/by_country', { params });
    },
};

export default StatisticService;

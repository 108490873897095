import {
    CAvatar,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderToggler,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { cilMenu, cilPowerStandby } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import LabelRole from 'src/components/DataTable/LabelRole';
import { setSidebarShow } from 'src/store/layout';
import { logout, logoutFakeAdmin } from 'src/store/user';

const AppHeader = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.layout.sidebarShow);
    const userData = useSelector((state) => state.user.userData);
    const isFakeAdmin = useSelector((state) => state.user.isFakeAdmin);

    function handleLogoutFakeAdmin() {
        dispatch(logoutFakeAdmin(1));
    }
    return (
        <CHeader position="sticky" className="mb-4">
            <CContainer fluid>
                <CHeaderToggler className="ps-1" onClick={() => dispatch(setSidebarShow(!sidebarShow))}>
                    <CIcon icon={cilMenu} size="lg" />
                </CHeaderToggler>
                <CHeaderBrand className="mx-auto d-md-none">
                    <img src="" alt="" />
                </CHeaderBrand>
                <CHeaderNav className="ms-3 d-flex align-items-center">
                    <div>
                        {isFakeAdmin ? (
                            <span className="d-inline-flex me-2 badge bg-warning clink" onClick={handleLogoutFakeAdmin}>
                                Back
                            </span>
                        ) : (
                            ''
                        )}
                        <span className="d-inline-flex me-2">{userData.name}</span> <LabelRole role={userData.role} />
                    </div>
                    <CDropdown variant="nav-item">
                        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
                            <CAvatar src="/images/default_avatar.png" size="md" />
                        </CDropdownToggle>
                        <CDropdownMenu className="pt-0" placement="bottom-end">
                            <CDropdownItem onClick={() => dispatch(logout())}>
                                <CIcon icon={cilPowerStandby} className="me-2" />
                                Logout
                            </CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    );
};

export default AppHeader;

import { CFormLabel, CFormSelect } from '@coreui/react';
import PropTypes from 'prop-types';

const SelectField = (props) => {
    const { field, form, label, disabled, isRequired, options } = props;
    const { name } = field;
    const { errors, touched } = form;
    let showError = errors[name] && touched[name];
    if (errors[name] && props.title) {
        errors[name] = errors[name].replace(name, props.title);
    }
    return (
        <>
            <div className="mb-3">
                <CFormLabel htmlFor={name}>
                    {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : null}
                </CFormLabel>
                <CFormSelect id={name} {...field} disabled={disabled} options={options} />
                {showError && <p className={'text-danger'}>{errors[name]}</p>}
            </div>
        </>
    );
};

SelectField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
};

SelectField.defaultProps = {
    label: '',
    disabled: false,
    isRequired: false,
};

export default SelectField;

export const App = {
    TIMESTAMP_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DATE_FORMAT_REDIS: 'YYYY-MM-DD.HH:mm',
    DATE_FORMAT: 'YYYY-MM-DD',
    WALLET_TYPE_IN: 1,
    WALLET_TYPE_OUT: 2,
};

export const ROLES = {
    ROLE_SUPER: 1,
    ROLE_MEMBER: 2,
    ROLE_LEADER: 3,
};

export const ROLE_GROUP_MOMX = [ROLES.ROLE_SUPER];

export const ROLE_LABEL = {
    1: { text: 'Admin', color: 'primary' },
    2: { text: 'Member', color: 'warning' },
    3: { text: 'Leader', color: 'danger' },
};
export const ROLE_OPTIONS = [
    { label: 'Admin', value: 1 },
    { label: 'Member', value: 2 },
    { label: 'Leader', value: 3 },
];

export const ACTIVE_OPTIONS = [
    {
        label: 'Deactive',
        value: '0',
    },
    {
        label: 'Active',
        value: 1,
    },
];

export const GET_TOP_ONLINE_DELAY = 20 * 1000;
export const GET_CURRENT_ONLINE_DELAY = 20 * 1000;

export const WALLET_TYPE = {
    1: 'In',
    2: 'Out',
};

export const DOWNLOAD_STATUS_PENDING = 0;
export const DOWNLOAD_STATUS_DOWNLOADING = 1;
export const DOWNLOAD_STATUS_DOWNLOADED = 2;
export const DOWNLOAD_STATUS_ERROR = 3;
export const DOWNLOAD_STATUS_CONVERTING = 4;

export const DOWNLOAD_STATUSES = {
    [DOWNLOAD_STATUS_PENDING]: 'Pending',
    [DOWNLOAD_STATUS_DOWNLOADING]: 'Downloading',
    [DOWNLOAD_STATUS_DOWNLOADED]: 'Downloaded',
    [DOWNLOAD_STATUS_ERROR]: 'Error',
    [DOWNLOAD_STATUS_CONVERTING] : 'Converting',
};

export const DOWNLOAD_STATUS_OPTIONS = [
    { label: 'Pendding', value: DOWNLOAD_STATUS_PENDING },
    { label: 'Downloading', value: DOWNLOAD_STATUS_DOWNLOADING },
    { label: 'Downloaded', value: DOWNLOAD_STATUS_DOWNLOADED },
    { label: 'Error', value: DOWNLOAD_STATUS_ERROR },
];

export const STREAM_URLS = [
    { label: 'Facebook', value: 'rtmps://live-api-s.facebook.com:443/rtmp/' },
    { label: 'Youtube', value: 'rtmp://a.rtmp.youtube.com/live2/' },
];

import HasRole from 'src/components/auth/HasRole';
import { ROLES } from 'src/constants';
import { number_format } from 'src/utils/number';

const Card = ({ title, color = 'info', left, right, online = false }) => {
    return (
        <div className="card">
            <div className={`card-header bg-${color} content-center text-white text-value-lg`} style={{ height: 60 }}>
                {title}
            </div>
            <div className="card-body text-center p-0 justify-content-center">
                <div className="card-col">
                    <div className="text-number text-value-lg mb-1">
                        {online ? (
                            <div className="ring-container">
                                <div className="ringring"></div>
                                <div className="circle"></div>
                            </div>
                        ) : (
                            ''
                        )}

                        {number_format(left.number)}
                    </div>
                    <h4 className="text-number text-uppercase  small">{left.text}</h4>
                </div>
                <HasRole roles={[ROLES.ROLE_LEADER]}>
                    
                </HasRole>
            </div>
        </div>
    );
};

export default Card;

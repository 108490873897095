import { CFormLabel } from '@coreui/react';
import PropTypes from 'prop-types';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
const Json = (props) => {
    const { field, form, label, disabled, isRequired, attributes = {}, formikProps } = props;
    const { name } = field;
    const { errors, touched } = form;
    let showError = errors[name] && touched[name];
    if (errors[name] && props.title) {
        errors[name] = errors[name].replace(name, props.title);
    }

    const value = field.value ? JSON.parse(field.value) : null;
    function onChange(event) {
        console.log('on change');
        if (!event.error) {
            formikProps.setFieldValue(name, event.json);
        }
    }
    return (
        <>
            <div className="mb-3">
                <CFormLabel htmlFor={name}>
                    {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : null}
                </CFormLabel>
                <JSONInput
                    width="100%"
                    height="200px"
                    id={name}
                    placeholder={value}
                    onBlur={onChange}
                    viewOnly={disabled}
                    locale={locale}
                    onKeyPressUpdate={true}
                    {...attributes}
                />
                {showError && <p className={'text-danger'}>{errors[name]}</p>}
            </div>
        </>
    );
};

Json.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
};

Json.defaultProps = {
    label: '',
    disabled: false,
    isRequired: false,
};

export default Json;

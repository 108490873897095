import request from 'src/utils/request';

const DomainService = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/domain', { params });
        return data;
    },
    async show(id) {
        return await request.get(`/admin/domain/${id}`);
    },
    async update(id, data) {
        delete data.id;
        return await request.put(`/admin/domain/${id}`, data);
    },
    async create(data) {
        return await request.post(`/admin/domain`, data);
    },
    async delete(id) {
        return await request.delete(`/admin/domain/${id}`);
    },
};

export default DomainService;

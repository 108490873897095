import { useEffect, useState } from 'react';
import HasPermission from 'src/components/auth/HasPermission';
import { GET_CURRENT_ONLINE_DELAY } from 'src/constants';
import { VIEW_LEADERBOARD } from 'src/constants/permission.const';
import storage from 'src/core/storage';
import { delay } from 'src/helpers';
import StatisticService from 'src/services/statistic.service';
import LeaderBoard from './LeaderBoard';
import Card from './components/Card';
import { useSelector } from 'react-redux';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';

const Dashboard = () => {
    const [current, setCurrent] = useState({
        online: 0,
        rpm: 0,
        total_money: 0,
        total_views: 0,
    });

    const [thisWeek, setThisWeek] = useState({
        total_money: 0,
        total_views: 0,
    });

    const [thisMonth, setThisMonth] = useState({
        total_money: 0,
        total_views: 0,
    });

    const { NOTIFY_DASHBOARD } = useSelector((state) => state.layout.configs);

    useEffect(() => {
        fetchData();
        loopGetCurrent();
        storage.set('loopGetCurrent', true);
        return () => {
            storage.set('loopGetCurrent', false);
        };
    }, []);

    async function loopGetCurrent() {
        const dataCurrent = await StatisticService.getCurrent();
        setCurrent(dataCurrent);
        const loopStatus = storage.get('loopGetCurrent');
        await delay(GET_CURRENT_ONLINE_DELAY);
        if (loopStatus) {
            loopGetCurrent();
        }
    }

    async function fetchData() {
        const [dataWeek, dataMonth] = await Promise.all([
            await StatisticService.getThisWeek(),
            await StatisticService.getThisMonth(),
        ]);
        setThisWeek(dataWeek);

        setThisMonth(dataMonth);
    }

    return (
        <>
            {NOTIFY_DASHBOARD ? (
                <CCard className="mb-3">
                    <CCardHeader>Notification</CCardHeader>
                    <CCardBody>{NOTIFY_DASHBOARD}</CCardBody>
                </CCard>
            ) : (
                ''
            )}
            <div id="dashboard-widget">
                <div className="row row5 mb-3">
                    <div className="col-12 col-sm-6 col-xl-3 mb-4">
                        <Card
                            title="Right Now"
                            color="success"
                            online
                            left={{
                                number: current.online,
                                text: 'Online',
                            }}
                            right={{
                                number: current.rpm,
                                text: 'RPM $',
                            }}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 mb-4">
                        <Card
                            title="Today"
                            color="warning"
                            left={{
                                number: current.total_views,
                                text: 'IMPRESSIONS',
                            }}
                            right={{
                                number: current.total_money,
                                text: 'USD',
                            }}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 mb-4">
                        <Card
                            title="This Week"
                            color="primary"
                            left={{
                                number: thisWeek.total_views,
                                text: 'IMPRESSIONS',
                            }}
                            right={{
                                number: thisWeek.total_money,
                                text: 'USD',
                            }}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-xl-3 mb-4">
                        <Card
                            title="This Month"
                            color="danger"
                            left={{
                                number: thisMonth.total_views,
                                text: 'IMPRESSIONS',
                            }}
                            right={{
                                number: thisMonth.total_money,
                                text: 'USD',
                            }}
                        />
                    </div>
                </div>
            </div>
            <HasPermission permission={VIEW_LEADERBOARD}>
                <LeaderBoard />
            </HasPermission>
        </>
    );
};

export default Dashboard;

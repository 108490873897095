import request from 'src/utils/request';
import { BASE_API } from 'src/configs';
import axios from 'axios';

const UserService = {
    async login(data) {
        return await request.post('/admin/auth/login', data);
    },
    async loginById(id) {
        return await request.get(`/admin/auth/loginById/${id}`);
    },
    logout() {
        localStorage.removeItem('access_token');
        window.location.reload();
    },

    async profile() {
        return await request.get('/admin/profile');
    },
    async refresh() {
        return await request.post('/admin/auth/refresh');
    },
    refreshToken(token) {
        let config = {
            method: 'POST',
            url: BASE_API + '/admin/auth/refresh_token',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        };
        return axios(config);
    },
};

export default UserService;

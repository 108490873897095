const storage = {
    data: {},
    get(key, dataDefault) {
        return this.data[key] ?? dataDefault;
    },

    set(key, value) {
        this.data[key] = value;
    },
};

export default storage;

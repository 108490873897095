import { CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BaseForm from 'src/components/Form/BaseForm';
import SettingService from 'src/services/setting.service';
import { internalConfigs } from 'src/store/layout';
import notify from 'src/utils/notify';
import { formConfigs } from './function';

const SettingConfig = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [config, setConfig] = useState({
        fields: [],
        data: {},
    });
    const dispatch = useDispatch();

    useEffect(() => {
        fetchConfig();
    }, []);

    function onSubmit(data) {
        setIsLoading(true);
        SettingService.updateConfig(data)
            .then((res) => {
                notify.success('Thành công');

                fetchConfig();
                dispatch(internalConfigs());

            })
            .catch(notify.response)
            .finally(() => setIsLoading(false));
    }

    function fetchConfig() {
        SettingService.getFields({ module: 'config' })
            .then((res) => {
                setConfig(res);
            })
            .catch(notify.response)
            .finally(() => setIsLoading(false));
    }

    const { settings } = formConfigs({ fields: config.fields });
    return (
        <>
            <CCard className="mb-4">
                <CCardHeader>
                    <CCardTitle className="d-flex justify-content-between align-items-center">
                        Cấu hình chung
                    </CCardTitle>
                </CCardHeader>

                <CCardBody>
                    <BaseForm settings={settings} data={config.data} onSubmit={onSubmit} isLoading={isLoading} />
                </CCardBody>
            </CCard>
        </>
    );
};

export default SettingConfig;

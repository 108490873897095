import { useEffect, useState } from 'react';
import { GET_TOP_ONLINE_DELAY } from 'src/constants';
import storage from 'src/core/storage';
import { delay } from 'src/helpers';
import StatisticService from 'src/services/statistic.service';
import { number_format } from 'src/utils/number';


const LeaderBoard = () => {
    const [topOnline, setTopOnline] = useState([]);
    const [topViewToDay, setTopViewToDay] = useState([]);
    const [topViewThisMonth, setTopViewThisMonth] = useState([]);

    useEffect(() => {
        storage.set('loopGetToopOnline', true);
        fetchData();
        return () => {
            storage.set('loopGetToopOnline', false);
        };
    }, []);

    function fetchData() {
        getTopOnline();
        getTopViewToDay();
        getTopViewThisMonth();
    }

    async function getTopOnline() {
        const { data } = await StatisticService.getTopOnline();
        setTopOnline(data);
        await delay(GET_TOP_ONLINE_DELAY);
        if (storage.get('loopGetToopOnline')) {
            getTopOnline();
        }
    }
    async function getTopViewToDay() {
        const { data } = await StatisticService.getTopViewToDay();
        setTopViewToDay(data);
    }
    async function getTopViewThisMonth() {
        const { data } = await StatisticService.getTopViewThisMonth();
        setTopViewThisMonth(data);
    }

    return (
        <>
            <div className="row row5">
                <div className="col-12 col-lg-4  mb-3 ">
                    <div className="card h-100">
                        <div className="card-body pb-0 top-team">
                            <div className="item-amount text-center" style={{ minHeight: 360 }}>
                                <h5>Top Onlines</h5>
                                {topOnline.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-flex justify-content-between align-items-center  mb-2"
                                        >
                                            <span>
                                                {index + 1}. {i.name}{' '}
                                            </span>
                                            <span className="text-danger">{i.online}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4  mb-3 ">
                    <div className="card h-100">
                        <div className="card-body pb-0 top-team">
                            <div className="item-amount text-center" style={{ minHeight: 360 }}>
                                <h5>Top Of The Day</h5>
                                {topViewToDay.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-flex justify-content-between align-items-center  mb-2"
                                        >
                                            <span>
                                                {index + 1}. {i.name}{' '}
                                            </span>
                                            <span className="text-danger">{number_format(i.views)}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4  mb-3 ">
                    <div className="card h-100">
                        <div className="card-body pb-0 top-team">
                            <div className="item-amount text-center" style={{ minHeight: 360 }}>
                                <h5>Top Of The Month</h5>
                                {topViewThisMonth.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="d-flex justify-content-between align-items-center  mb-2"
                                        >
                                            <span>
                                                {index + 1}. {i.name}{' '}
                                            </span>
                                            <span className="text-danger">{number_format(i.views)}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeaderBoard;

export function typeOf(value) {
    return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

let lastLog = 0;

export function delayLog(data, delaySeconds = 1, callback) {
    const current = new Date().getTime();
    const delayTime = delaySeconds * 1000;
    if (current - lastLog > delayTime) {
        if (typeof callback === 'function') {
            callback(data);
        } else {
            if (typeof data === 'function') {
                data = data();
            }
            console.log(data);
        }
        lastLog = current;
    }
}

export function copyInstance(original) {
    var copied = Object.assign(Object.create(Object.getPrototypeOf(original)), original);
    return copied;
}

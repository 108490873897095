import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from 'src/services/user.service';
import notify from 'src/utils/notify';
const {
    getToken,
    setToken,
    deleteToken,
    setFakeAdmin,
    deleteFakeAdmin,
    isFakeAdmin,
    rollbackSuperToken,
} = require('src/utils/auth');

const access_token = getToken();

const initialState = {
    isLogged: !!access_token,
    role: '',
    permissions: [],
    isLoading: false,
    isLoadingInfo: true,
    userData: {},
    isFakeAdmin: isFakeAdmin(),
};

export const login = createAsyncThunk('user/login', async (params, thunkAPI) => {
    try {
        const { data } = await UserService.login(params);
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue();
    }
});

export const getProfile = createAsyncThunk('user/getProfile', async (params, thunkAPI) => {
    try {
        return await UserService.profile(params);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const loginById = createAsyncThunk('user/loginById', async (id, thunkAPI) => {
    try {
        const data = await UserService.loginById(id);

        setTimeout(() => {
            thunkAPI.dispatch(getProfile());
        }, 100);

        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const logoutFakeAdmin = createAsyncThunk('user/logoutFakeAdmin', async (payload, thunkAPI) => {
    try {
        rollbackSuperToken();
        deleteFakeAdmin();

        setTimeout(() => {
            thunkAPI.dispatch(getProfile());
        }, 100);
        return true;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout(state) {
            state.isLogged = false;
            state.role = '';
            deleteToken();
            deleteFakeAdmin();
        },
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.isLoading = true;
        },
        [login.fulfilled]: (state, { payload: data }) => {
            state.isLoading = false;
            state.isLogged = true;
            setToken({
                access_token: data.access_token,
                refresh_token: data.refresh_token,
            });
        },
        [login.rejected]: (state) => {
            notify.error('Sai thông tin tài khoản hoặc mật khẩu');
            state.isLoading = false;
        },

        [getProfile.pending]: (state) => {
            state.isLoadingInfo = true;
            // state.isLoading = true;
        },
        [getProfile.fulfilled]: (state, { payload }) => {
            const { admin } = payload;
            state.userData = admin;
            state.isLogged = true;
            state.role = admin.role;
            state.permissions = admin.permissions;

            state.isLoadingInfo = false;
        },
        [getProfile.rejected]: (state) => {
            state.isLogged = false;
        },
        [loginById.fulfilled]: (state, action) => {
            const { data } = action.payload;
            state.isFakeAdmin = true;
            setFakeAdmin({
                access_token: data.access_token,
                refresh_token: data.refresh_token,
            });
        },
        [logoutFakeAdmin.fulfilled]: (state, action) => {
            state.isFakeAdmin = false;
        },
    },
});

const { reducer: userReducer } = userSlice;
export const { logout } = userSlice.actions;

export default userReducer;

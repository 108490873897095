import { useEffect, useState } from 'react';
import { ROLES } from 'src/constants';
import AdminService from 'src/services/admin.service';
import useRoles from './useRoles';

const useLeaders = (placeholder = true) => {
    const [leaders, setLeaders] = useState([]);
    const [checkRole] = useRoles();
    useEffect(() => {
        fetchLeaders();
        return () => {};
    }, []);
    function fetchLeaders() {
        if (!checkRole([ROLES.ROLE_SUPER, ROLES.ROLE_LEADER])) return;
        AdminService.leaders().then((res) => {
            if (placeholder) {
                res.unshift({ label: 'Select Leader', value: '' });
            }
            setLeaders(res);
        });
    }
    return [leaders];
};

export default useLeaders;

import * as Yup from 'yup';

import ImageField from 'src/components/Form/ImageField';
import InputField from 'src/components/Form/InputField';
import Textarea from 'src/components/Form/Textarea';
import Json from 'src/components/Form/Json';

export const parseRuleValidate = (ruleString) => {
    const ruleArray = ruleString.split('|').filter((i) => i !== '');
    return ruleArray.reduce((before, i) => {
        const [rule, params] = i.split(':');
        if (!params) {
            return before[rule]();
        } else {
            const paramsArray = params.split(',');
            return before[rule](...paramsArray);
        }
    }, Yup);
};

export const formConfigs = ({ fields, data = {} }) => {
    const initialValues = {};
    const validationSchema = {};
    const settings = fields.map((i) => {
        let component;
        initialValues[i.field] = data[i.field] ?? '';
        if (i.validate) {
            const rules = parseRuleValidate(i.validate);
            if (rules) {
                validationSchema[i.field] = rules;
            }
        }
        switch (i.type) {
            case 'image':
                component = ImageField;
                break;
            case 'textarea':
                component = Textarea;
                break;
            case 'json':
                component = Json;
                break;
            default:
                component = InputField;
                break;
        }
        return {
            label: i.name,
            name: i.field,
            component,
            col: i.col ?? 6,
        };
    });

    return {
        settings,
        initialValues,
        validationSchema: Yup.object().shape(validationSchema),
        data: initialValues,
    };
};

function getTypeFormSetting(setting, type) {
    let options = {};
    switch (type) {
        case 'create':
            options = setting?.onCreate ?? {};
            break;
        case 'update':
            options = setting?.onUpdate ?? {};
            break;

        default:
            break;
    }
    const { attributes = {}, validate = '' } = options;
    return { attributes, validate };
}

export function getSettingForm(settings, type = 'create') {
    const { initialValues, rulesValidate, newSettings, defaultData } = settings.reduce(
        ({ initialValues, rulesValidate, newSettings, defaultData }, i) => {
            initialValues[i.name] = i.defaut_value ? i.defaut_value : '';
            const typeSetting = getTypeFormSetting(i, type);
            const rule = typeSetting.validate ? typeSetting.validate : i.validate ?? '';
            if (rule) {
                rulesValidate[i.name] = parseRuleValidate(rule) ?? undefined;
            }

            const component = i.component ?? InputField;
            const col = i.col ? i.col : 6;
            const name = i.name;
            const label = i.label ?? i.name;
            const attributes = i.attributes ?? {};
            const isRequired = !!(rule && rule.search('required') !== -1);
            const render = typeof i.render === 'function' ? i.render : ({ i }) => i;
            const format = typeof i.format === 'function' ? i.format : ({ value, formData }) => value;
            newSettings.push({
                component,
                col,
                label,
                name,
                attributes: {
                    ...attributes,
                    ...typeSetting.attributes,
                },
                isRequired,
                render,
                format,
                roles: i.roles,
            });
            if (i.default) {
                defaultData[i.name] = i.default;
            }
            return {
                initialValues,
                rulesValidate,
                newSettings,
                defaultData,
            };
        },
        {
            initialValues: {},
            rulesValidate: {},
            newSettings: [],
            defaultData: {},
        }
    );

    const validationSchema = Yup.object().shape(rulesValidate);

    return { initialValues, validationSchema, newSettings, defaultData };
}

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { DEFAULT_PATH } from 'src/constants/app.const';

const PublicRoute = ({ children }) => {
    const isLogged = useSelector((state) => state.user.isLogged);
    return !isLogged ? children : <Navigate to={DEFAULT_PATH} />;
};

export default PublicRoute;

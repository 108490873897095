import { ERROR_CODE_MESSAGE } from 'src/constants/response_code.const';

import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

const notify = {
    warning(message) {
        Toast.fire({
            icon: 'warning',
            title: message,
        });
    },
    success(message = 'Successful!') {
        Toast.fire({
            icon: 'success',
            title: message,
        });
    },
    error(message) {
        Toast.fire({
            icon: 'error',
            title: message,
        });
    },
    errorCode(errorCode) {
        const message = ERROR_CODE_MESSAGE[errorCode] || '';
        if (message) {
            this.error(message);
        }
    },
    confirm(message = 'Are you sure that you want to delete this record?') {
        return Swal.fire({
            titleText: message,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
        });
    },
    response(error) {
        const status = error?.response.status || 500;
        
        const message = ERROR_CODE_MESSAGE[status] ? ERROR_CODE_MESSAGE[status] : (error?.response?.data?.message ?? 'System busy');
        notify.warning(message);
    },
};

export default notify;

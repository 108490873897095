import React from 'react';
import useRoles from 'src/hooks/useRoles';

const HasRole = ({ roles, children }) => {
    const [checkRole] = useRoles();
    if (checkRole(roles)) {
        return <>{children}</>;
    }
};

export default HasRole;

import { DATE_TIME_FORMAT } from 'src/constants/app.const';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export const formatDate = (date, format = DATE_TIME_FORMAT) => {
    return moment(date).format(format);
};

export function convertTimezone(from, to, value) {
    const hours = from - to;
    if (!value) {
        return null;
    }
    return value - hours * 60 * 60 * 1000;
}

export function getTimezone() {
    const d = new Date();
    return (-1 * d.getTimezoneOffset()) / 60;
}

export function convertTimeUTC0(day) {
    return momentTimezone.utc(day).hour(0).minute(0).second(0).millisecond(0).format('x');
}

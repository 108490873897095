import { useEffect, useState } from 'react';
import { ROLES } from 'src/constants';
import AdminService from 'src/services/admin.service';
import useRoles from './useRoles';

const useAdmins = (params = {}) => {
    const [admins, setAdmins] = useState([]);
    const [checkRole] = useRoles();
    useEffect(() => {
        if (!checkRole([ROLES.ROLE_LEADER])) return;
        AdminService.list({
            page: 1,
            page_size: 1000,
            active: 1,
            ...params,
        }).then(({ list }) => {
            const options = list.map((i, index) => {
                return {
                    label: i.name,
                    value: i.id,
                };
            });
            options.unshift({
                label: 'Select User',
                value: '',
            });
            setAdmins(options);
        });
    }, []);

    return [admins, setAdmins];
};

export default useAdmins;

import request from 'src/utils/request';

const VideoService = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/video', { params });
        return data;
    },
    async show(id) {
        return await request.get(`/admin/video/${id}`);
    },
    async update(id, data) {
        return await request.put(`/admin/video/${id}`, data);
    },
    async create(data) {
        return await request.post(`/admin/video`, data);
    },
    async delete(id) {
        return await request.delete(`/admin/video/${id}`);
    },
    async startLive(id) {
        return await request.put(`/admin/video/${id}/start`);
    },

    async stopLive(id) {
        return await request.put(`/admin/video/${id}/stop`);
    },

    async download(id) {
        return await request.put(`/admin/video/${id}/download`);
    },
};

export default VideoService;

import { number_format } from 'src/utils/number';
import { LIVE_STATIC_HOST } from 'src/configs';

export async function delay(ms) {
    return await new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export function str_random(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function format_money(money) {
    return number_format((money * 1).toFixed(2)) + ' $';
}

export function get_form_data(e) {
    const formData = new FormData(e.target);
    const data = {};
    for (const [key, value] of formData) {
        data[key] = value;
    }

    return data;
}

export function get_static_url(url) {
    return `${LIVE_STATIC_HOST}/${url}`;
}

import request from 'src/utils/request';

const CountryService = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/country', { params });
        return data;
    },
    async show(id) {
        return await request.get(`/admin/country/${id}`);
    },
    async update(id, data) {
        delete data.id;
        return await request.put(`/admin/country/${id}`, data);
    },
};

export default CountryService;

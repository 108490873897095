import { CButton, CCardBody, CCardText, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { useEffect, useState } from 'react';
import GridTable from 'src/components/GridTable';
import StatisticService from 'src/services/statistic.service';
import notify from 'src/utils/notify';
import { number_format } from 'src/utils/number';
import StatisticDetailAdmin from './StatisticDetailAdmin';
import { ROLES } from 'src/constants';
import HasRole from 'src/components/auth/HasRole';
import { format_money } from 'src/helpers';

const StatisticByAdmin = ({ queryParams }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        list: [],
        total: {
            total_views: 0,
            total_money: 0,
        },
    });
    useEffect(() => {
        fetchData();
    }, [queryParams]);

    const [dataDetail, setDataDetail] = useState({
        showModal: false,
        list: [],
    });
    const showAdminDetail = (admin_id, params) => {
        StatisticService.detailAdmin(admin_id, params).then((res) => {
            setDataDetail({
                showModal: true,
                list: res.data,
            });
        });
    };

    const setting = [
        { label: 'Id', column: 'admin_id' },
        { label: 'Name', column: 'name' },
        {
            label: 'Total Impressions',
            column: 'views',
            render: ({ item }) => {
                return number_format(item.views);
            },
        },
        {
            label: 'Total Money',
            column: 'money',
            render: ({ item }) => {
                return number_format((item.money * 1).toFixed(2)) + ' $';
            },
            roles: [ROLES.ROLE_SUPER],
        },
        {
            label: 'Act',
            column: 'money',
            render: ({ item, extraData: params }) => {
                return (
                    <CButton className="btn-sm" onClick={() => showAdminDetail(item.admin_id, params)}>
                        Detail
                    </CButton>
                );
            },
        },
    ];

    function fetchData() {
        setIsLoading(true);
        StatisticService.byAdmin(queryParams)
            .then((data) => {
                setData(data);
            })
            .catch(notify.response)
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <CModal
                visible={dataDetail.showModal}
                size="lg"
                onClose={() => setDataDetail((pre) => ({ ...pre, showModal: false }))}
            >
                <CModalHeader>
                    <CModalTitle>
                        Details of user {dataDetail.list?.length ? dataDetail.list[0].name : ''}
                        <div>
                            from {queryParams.start_date}{' '}
                            {queryParams.end_date ? <> to {queryParams.end_date}</> : ''}
                        </div>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <StatisticDetailAdmin queryParams={queryParams} data={dataDetail.list} />
                </CModalBody>
            </CModal>
            <CCardBody>
                <CCardText>Impressions {number_format(data.total.total_views)}</CCardText>
                <HasRole roles={[ROLES.ROLE_SUPER]}>
                    <CCardText>Total Money {format_money(data.total.total_money)}</CCardText>
                </HasRole>
            </CCardBody>

            <GridTable
                setting={setting}
                data={data}
                pagination={false}
                extraData={queryParams}
                isLoading={isLoading}
                showLoading={true}
            />
        </>
    );
};

export default StatisticByAdmin;

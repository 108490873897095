import React, { forwardRef } from 'react';
import BaseForm from './Form/BaseForm';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCardTitle,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import CreateButton from './DataTable/CreateButton';
import { useState } from 'react';
import { useEffect } from 'react';
import notify from 'src/utils/notify';
import GridTable from './GridTable';
import { useImperativeHandle } from 'react';

const GridResource = forwardRef(
    (
        {
            gridSettings,
            formSettings = null,
            service,
            moduleName = '',
            extraData = {},
            actions = ['create', 'reload'],
            params = {
                page: 1,
                page_size: 20,
            },
            customSubmit,
            customActions = {},
            hook = (event, data) => {},
        },
        ref
    ) => {
        const [queryParams, setQueryParams] = useState(params);

        useImperativeHandle(ref, () => ({
            reload() {
                fetchData();
            },
            onEdit,
            onDelete,
            setQueryParams,
            onSubmit,
        }));

        const [form, setForm] = useState({
            isActive: false,
        });
        const [data, setData] = useState({});
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            fetchData();
            hook('reload', queryParams);
        }, [queryParams]);

        useEffect(() => {
            hook('formChange', form);
        }, [toggleForm]);

        const listActions = {
            create: <CreateButton onClick={() => toggleForm('create')} />,
            reload: (
                <button type="button" className="btn m-1 btn-sm bg-primary" onClick={() => fetchData()}>
                    Reload
                </button>
            ),
            ...customActions,
        };
        function getAction(name) {
            if (typeof name === 'string') {
                return listActions[name] ?? '';
            } else return name?.render() ?? '';
        }

        async function fetchData() {
            const res = await service.list(queryParams);
            setData(res);
        }

        async function onEdit(item) {
            const data = await service.show(item.id);
            setForm({
                isActive: 1,
                type: 'update',
                data: data.data,
            });
        }
        function onDelete(item) {
            if (window.confirm('Are you sure?')) {
                service.delete(item.id).then(() => {
                    fetchData();
                });
            }
        }

        async function handleSubmit(data, actions) {
            if (typeof customSubmit === 'function') {
                return customSubmit(data, actions);
            }
            return onSubmit(data, actions);
        }
        async function onSubmit(data, { resetForm }) {
            if (isLoading) return;
            setIsLoading(true);
            try {
                if (data.id) {
                    await service.update(data.id, data);
                } else {
                    await service.create(data);
                }

                notify.success('Success');
                fetchData();
                setForm({
                    isActive: false,
                });
                resetForm();
                setIsLoading(false);
            } catch (error) {
                notify.response(error);
                setIsLoading(false);
            }
        }

        function toggleForm(type) {
            setForm((pre) => {
                return {
                    isActive: !pre.isActive,
                    type: type,
                };
            });
        }

        const onPageChange = (page) => {
            setQueryParams((pre) => {
                return { ...pre, page };
            });
        };

        return (
            <>
                {formSettings ? (
                    <>
                        <CModal
                            visible={!!form.isActive}
                            size="xl"
                            backdrop={'static'}
                            onClose={() => setForm((pre) => ({ ...pre, isActive: false }))}
                        >
                            <CModalHeader>
                                <CModalTitle>
                                    {form.data ? `Edit ${moduleName} ${form.data.name} ` : `Add ${moduleName}`}
                                </CModalTitle>
                            </CModalHeader>
                            <CModalBody>
                                <BaseForm
                                    onSubmit={handleSubmit}
                                    settings={formSettings}
                                    data={form.data}
                                    type={form.type}
                                    isLoading={isLoading}
                                />
                            </CModalBody>
                        </CModal>
                    </>
                ) : (
                    ''
                )}

                <CCard className="mb-4">
                    <CCardHeader>
                        <CCardTitle className="d-flex justify-content-between align-items-center">
                            List {moduleName}
                            <div className="d-flex">
                                {actions.map((i, key) => (
                                    <React.Fragment key={key}>{getAction(i)}</React.Fragment>
                                ))}
                            </div>
                        </CCardTitle>
                    </CCardHeader>
                    <CCardBody>
                        <GridTable
                            setting={gridSettings}
                            onPageChange={onPageChange}
                            data={data}
                            extraData={extraData}
                        />
                    </CCardBody>
                </CCard>
            </>
        );
    }
);

export default GridResource;

import { CButton, CCard, CCardBody, CFormInput } from '@coreui/react';

import Service from 'src/services/video.service';

import { useRef, useState } from 'react';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import DSelect from 'src/components/Form/DSelect';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import {
    DOWNLOAD_STATUSES,
    DOWNLOAD_STATUS_OPTIONS,
    ROLES,
    DOWNLOAD_STATUS_DOWNLOADED,
    DOWNLOAD_STATUS_PENDING,
    DOWNLOAD_STATUS_DOWNLOADING,
    DOWNLOAD_STATUS_CONVERTING,
    DOWNLOAD_STATUS_ERROR,
} from 'src/constants';
import { get_form_data } from 'src/helpers';
import { show_date } from 'src/helpers/date';
import useAdmins from 'src/hooks/useAdmins';
import useRoles from 'src/hooks/useRoles';
import notify from 'src/utils/notify';
import { Button } from 'react-bootstrap';

const Video = ({showAction = true}) => {
    const childRef = useRef();
    const [admins] = useAdmins();

    const formSettings = [{ label: 'Description', name: 'title', validate: 'string|nullable' }, {
        label: 'Video Link', name: 'video_link', validate: 'string|required',
    }];

    const gridSettings = [{ label: 'Id', column: 'id' }, { label: 'Title', column: 'title' }, {
        label: 'Status', column: 'status', render: ({ item }) => {
            return DOWNLOAD_STATUSES[item.status] ?? 'Pending';
        },
    },
    {
        label: 'Thumbnail', column: 'admin', render: ({ item }) => {
            return item.image_url ? <img src={item.image_url} alt={item.title} style={{ maxHeight: 80 }} /> : '';
        },
    },
    {
        label: 'Note', column: 'note', render: ({ item }) => {
            return item.status === DOWNLOAD_STATUS_ERROR ? item.note :'';
        },
    },
    { label: 'Link', column: 'video_link'},

        { label: 'Create By', column: 'admin', render: ({ item }) => item.admin },

        { label: 'Create At', column: 'created_at', render: ({ item }) => show_date(item.created_at) },


        ];

    if(showAction){
        gridSettings.push({
            label: 'Action', render: ({ item, extraData }) => {
                return (<div className='d-flex justify-content-center'>
                    {!!item.video_url && (
                        <a target='_blank' href={item.video_url} className='btn m-1 btn-sm btn-success'>
                            Watch Video
                        </a>)}
                    {!!(item.status === DOWNLOAD_STATUS_DOWNLOADED || item.status === DOWNLOAD_STATUS_PENDING) &&
                        <DeleteButton onClick={() => childRef.current.onDelete(item)} />}
                    {!!(item.status === DOWNLOAD_STATUS_DOWNLOADING || item.status === DOWNLOAD_STATUS_CONVERTING) && (
                        <DeleteButton disabled />)}
                    {!!(item.status === DOWNLOAD_STATUS_ERROR) && (
                        <>
                        <Button className='btn m-1 btn-sm btn-warning' onClick={()=>handleRetryDownload(item)}>Retry </Button>
                        <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                        </>
                        )}
                </div>);
            },
        })
    }
    function handleRetryDownload(live) {

        Service.download(live.id)
            .then((data) => {
                if (data.status === 'error') {
                    notify.error(data.message);
                    return;
                }
                notify.success('Added to queue');
                childRef.current.reload();
            })

    }

    function onSubmitForm(e) {
        e.preventDefault();
        const data = get_form_data(e);

        childRef.current.setQueryParams((pre) => ({ ...pre, ...data, page: 1 }));
    }

    async function onSubmit(data, actions) {
        childRef.current.onSubmit(data, actions);
    }

    return (<>
        <CCard className='mb-4'>
            <CCardBody className='pb-0'>
                <form className='row mb-4' onSubmit={onSubmitForm}>
                    <HasRole roles={[ROLES.ROLE_LEADER]}>
                        <div className='col-3'>
                            <label htmlFor=''>User</label>
                            <DSelect name='admin_id' options={admins} />
                        </div>
                    </HasRole>
                    <div className='col-3'>
                        <label htmlFor=''>Create At</label>
                        <CFormInput type='date' name='date'></CFormInput>
                    </div>
                    <div className='col-3'>
                        <label htmlFor=''>Live Status</label>
                        <DSelect
                            name='status'
                            options={[{ label: 'Status', value: '' }, ...DOWNLOAD_STATUS_OPTIONS]}
                        />
                    </div>
                    <div className='col-12 mt-2'>
                        <CButton type='submit' className='btn-sm'>
                            Tìm
                        </CButton>
                    </div>
                </form>
            </CCardBody>
        </CCard>

        <GridResource
            ref={childRef}
            moduleName='Video'
            gridSettings={gridSettings}
            formSettings={formSettings}
            service={Service}
            customSubmit={onSubmit}
        />
    </>);
};
export default Video;

import { useSelector } from 'react-redux';
import { ROLES } from 'src/constants';

const usePermission = () => {
    const permissions = useSelector((state) => state.user.permissions);
    const role = useSelector((state) => state.user.role);

    const checkPermissions = (permission) => {
        if (role === ROLES.ROLE_SUPER) return true;

        return permissions.indexOf(permission) !== -1;
    };

    return [checkPermissions];
};

export default usePermission;

import React from 'react';

const DSelect = ({
    options,
    key_value = 'value',
    key_label = 'label',
    defaultSelected = '',
    placeholder = '',
    ...attributes
}) => {
    return (
        <select className="form-select" name="admin_id" {...attributes} defaultValue={defaultSelected}>
            {placeholder ? <option value="">{placeholder}</option> : ''}
            {options.map((i) => {
                return (
                    <option value={i[key_value]} key={i[key_value]}>
                        {i[key_label]}
                    </option>
                );
            })}
        </select>
    );
};

export default DSelect;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SettingService from 'src/services/setting.service';

const SIDEBAR_SHOW_KEY = 'sidebarShow';

const initialState = {
    sidebarShow: localStorage.getItem(SIDEBAR_SHOW_KEY) !== 'off',
    configs: {},
    public_configs: {},
};

export const publicConfigs = createAsyncThunk('layout/publicConfigs', async (params, thunkAPI) => {
    try {
        return await SettingService.publicConfigs();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const internalConfigs = createAsyncThunk('layout/internalConfigs', async (params, thunkAPI) => {
    try {
        return await SettingService.internalConfigs();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setSidebarShow(state, { payload }) {
            state.sidebarShow = payload;
            localStorage.setItem(SIDEBAR_SHOW_KEY, !!payload ? 'on' : 'off');
        },
    },
    extraReducers: {
        [publicConfigs.pending]: (state) => {},
        [publicConfigs.fulfilled]: (state, { payload }) => {
            state.public_configs = payload.configs;
        },
        [publicConfigs.rejected]: (state) => {},
        [internalConfigs.fulfilled]: (state, { payload }) => {
            state.configs = payload.configs;
        },
    },
});

const { reducer: layoutReducer } = layoutSlice;

export const { setSidebarShow } = layoutSlice.actions;

export default layoutReducer;

import { CFormInput, CFormLabel, CInputGroup } from '@coreui/react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
const ImageField = (props) => {
    const { field, form, label, disabled, isRequired, attributes = {}, formikProps } = props;
    const placeholder = props.placeholder ?? label;
    const { name } = field;
    const { errors, touched } = form;
    field.value = field.value === undefined ? '' : field.value;
    let showError = errors[name] && touched[name];
    if (errors[name] && props.title) {
        errors[name] = errors[name].replace(name, props.title);
    }
    const [imageUrl, setImageUrl] = useState(field.value ?? '');

    useEffect(() => {
        if (typeof field.value === 'string') {
            setImageUrl(field.value);
        }
        return () => {};
    }, [field.value]);

    function onChangeImageUpload(event) {
        let file = event.currentTarget.files[0];

        formikProps.setFieldValue(name, file);
        let path = URL.createObjectURL(file);
        setImageUrl(path);
    }
    function onChangeImageUrl(event) {
        const { value } = event.target;
        setImageUrl(value);
        formikProps.setFieldValue(name, value);
    }

    return (
        <>
            <div className="mb-3">
                <CFormLabel htmlFor={name}>
                    {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : null}
                </CFormLabel>
                <CInputGroup>
                    <CFormInput
                        type="text"
                        disabled={disabled}
                        placeholder={placeholder}
                        {...attributes}
                        value={imageUrl}
                        onChange={onChangeImageUrl}
                    />
                    <label className="btn btn-primary">
                        Upload
                        <CFormInput className="d-none" type="file" {...attributes} onChange={onChangeImageUpload} />
                    </label>
                </CInputGroup>
                {imageUrl ? (
                    <img width="100" height="100" style={{ objectFit: 'contain' }} src={imageUrl} alt=""></img>
                ) : (
                    ''
                )}
                {showError && <p className={'text-danger'}>{errors[name]}</p>}
            </div>
        </>
    );
};

ImageField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
};

ImageField.defaultProps = {
    type: 'file',
    label: '',
    disabled: false,
    isRequired: false,
};

export default ImageField;

export const ERROR_CODE = {
    permission_denied: 403,
    error_system: 500,
};

export const ERROR_CODE_DEFAULT = ERROR_CODE.error_system;

export const ERROR_CODE_MESSAGE = {
    [ERROR_CODE.permission_denied]: 'Permission denined',
    [ERROR_CODE.error_system]: 'System busy!',
};
export const STATUS_SUCCESS = 1000;


import { useRef } from 'react';
import EditButton from 'src/components/DataTable/EditButton';
import GridResource from 'src/components/GridResource';
import service from 'src/services/country.service';

const Country = () => {
    const childRef = useRef();
    const formSettings = [{ label: 'rpm', name: 'rpm', validate: 'string|required' }];
    const gridSettings = [
        {
            label: 'Name',
            column: 'name',
            render: ({ item }) => {
                return (
                    <>
                        {item.name} <img className="mx-2" height={15} src={item.img} alt="" />
                    </>
                );
            },
        },
        {
            label: 'Rpm',
            column: 'rpm',
        },
        {
            label: 'Act',
            render: ({ item }) => {
                return (
                    <div className="d-flex justify-content-center">
                        <EditButton onClick={() => childRef.current.onEdit(item)} />
                    </div>
                );
            },
        },
    ];

    return (
        <GridResource
            ref={childRef}
            moduleName="quốc gia"
            gridSettings={gridSettings}
            formSettings={formSettings}
            service={service}
            actions={['reload']}
        />
    );
};

export default Country;

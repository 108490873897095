import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user';
import layoutReducer from './layout';
import adminReducer from './admin';
const rootReducer = {
    layout: layoutReducer,
    user: userReducer,
    admin: adminReducer,
};

let store = configureStore({
    reducer: rootReducer,
});

export default store;

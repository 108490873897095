import request from 'src/utils/request';

const WalletService = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/wallet', { params });
        return data;
    },

    async show(id) {
        return await request.get(`/admin/wallet/${id}`);
    },
};

export default WalletService;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { getProfile } from 'src/store/user';
import LoadingWrap from '../LoadingWrap';
import AppFooter from './AdminLayout/AppFooter';
import AppHeader from './AdminLayout/AppHeader';
import AppSidebar from './AdminLayout/AppSidebar';
import { internalConfigs } from 'src/store/layout';

const AdminLayout = () => {
    const isLoadingInfo = useSelector((state) => state.user.isLoadingInfo);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfile());
        dispatch(internalConfigs());
       
    }, []);
    return (
        <>
            <LoadingWrap isLoading={isLoadingInfo}>
                <AppSidebar />
                <div className="wrapper d-flex flex-column min-vh-100">
                    <AppHeader />
                    <div className="body flex-grow-1 px-4">
                        <Outlet />
                    </div>
                    <AppFooter />
                </div>
            </LoadingWrap>
        </>
    );
};

export default AdminLayout;

import PropTypes from 'prop-types';
import usePermission from 'src/hooks/usePermission';
const HasPermission = ({ children, permission = '' }) => {
    const [checkPermissions] = usePermission();
    if (checkPermissions(permission)) {
        return <>{children}</>;
    }

    return null;
};
HasPermission.defaultProps = {
    permissions: {},
};
HasPermission.propTypes = {
    permissions: PropTypes.object,
};

export default HasPermission;

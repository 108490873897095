import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from '@coreui/react';
import { useEffect, useState } from 'react';
import DSelect from 'src/components/Form/DSelect';
import GridTable from 'src/components/GridTable';
import { format_money } from 'src/helpers';
import useAdmins from 'src/hooks/useAdmins';
import WalletService from 'src/services/wallet.service';
import WalletDetail from './WalletDetail';


const Wallet = () => {
    const [dataDetail, setDataDetail] = useState({
        showModal: false,
        list: [],
    });

    const [params, setParams] = useState({
        page: 1,
        page_size: 20,
    });
    const [admins] = useAdmins()
    const [data, setData] = useState([]);

    useEffect(() => {
        WalletService.list(params).then((res) => {
            setData(res);
        });
    }, [params]);

    const showWalletDetail = (wallet) => {
        WalletService.show(wallet.id).then((res) => {
            setDataDetail({
                showModal: true,
                list: res.data.detail,
            });
        });
    };
    const gridSettings = [
        { label: 'Id', column: 'id' },
        { label: 'Admin', column: 'admin_name' },
        {
            label: 'Money',
            render: ({ item }) => {
                return format_money(item.money);
            },
        },

        {
            label: 'Act',
            render: ({ item }) => {
                return (
                    <div className="d-flex justify-content-center">
                        <CButton className="btn-sm" onClick={() => showWalletDetail(item)}>
                            Chi tiết
                        </CButton>
                    </div>
                );
            },
        },
    ];
    function onPageChange(page) {
        setParams((pre) => ({ ...pre, page }));
    }

    function onSubmitForm(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const admin_id = formData.get('admin_id');
        setParams((pre) => ({ ...pre, admin_id, page: 1 }));
    }

    return (
        <>
            <CModal
                visible={dataDetail.showModal}
                size="lg"
                onClose={() => setDataDetail((pre) => ({ ...pre, showModal: false }))}
            >
                <CModalHeader>
                    <CModalTitle>Chi tiết </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <WalletDetail data={dataDetail.list} />
                </CModalBody>
            </CModal>

            <CCard className="mb-4">
                <CCardHeader>Ví </CCardHeader>
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <div className="col-3">
                            <label htmlFor="">Thành viên</label>
                            <DSelect options={admins} />
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Search
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
                <CCardBody>
                    <GridTable setting={gridSettings} data={data} onPageChange={onPageChange} />
                </CCardBody>
            </CCard>
        </>
    );
};

export default Wallet;

import request from 'src/utils/request';

const SettingService = {
    async getFields(params = {}) {
        return await request.get(`/admin/setting/fields`, { params });
    },
    async update(domain_name, data) {
        return await request.post(`/admin/setting/${domain_name}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    async updateConfig(data) {
        return await request.post(`/admin/setting/config`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    async publicConfigs(data) {
        return await request.get(`/admin/setting/public_configs`);
    },
    async internalConfigs(data) {
        return await request.get(`/admin/setting/internal_configs`);
    },
};

export default SettingService;

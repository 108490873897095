
const LABEL_STATUSES = {
    0: { text: 'Deactive', color: 'danger' },
    1: { text: 'Active', color: 'success' },
};
const LabelStatus = ({ status }) => {
    const label_status = LABEL_STATUSES[status] ?? { text: '', color: '' };
    return <span className={`badge bg-${label_status.color}`}>{label_status.text}</span>;
};

export default LabelStatus;

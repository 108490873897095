import { CNav, CNavItem, CNavLink } from '@coreui/react';
import { useState } from 'react';

const NavTab = ({ tabs, data = {}, defaultTab = 0 }) => {
    const [tabActive, setTabActive] = useState(tabs[defaultTab]);
    return (
        <>
            <CNav className="nav-underline">
                {tabs.map((item) => {
                    return (
                        <CNavItem key={item.name}>
                            <CNavLink
                                className={`clink ${tabActive.name === item.name ? 'active' : ''} `}
                                onClick={() => setTabActive(item)}
                            >
                                {item.label}
                            </CNavLink>
                        </CNavItem>
                    );
                })}
            </CNav>
            <div className="nav-content mt-3">
                <tabActive.component {...data} />
            </div>
        </>
    );
};

export default NavTab;

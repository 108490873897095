import axios from 'axios';
import { BASE_API } from 'src/configs';
import { AUTH_PATH } from 'src/constants/app.const';
import { deleteToken, execRefreshToken, getToken } from './auth';
import notify from './notify';

// Create axios instance
const request = axios.create({
    baseURL: BASE_API,
    timeout: 30000, // Request timeout
    headers: {
        'Content-Type': 'application/json',
    },
});

request.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
        }
        return config;
    },
    (error) => {
        console.log(error); // for debug
        Promise.reject(error);
    }
);

request.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        const status = error?.response.status || 500;
        notify.errorCode(status);
        const urlLogin = '/admin/auth/login';
        if (status === 401 && error.config.url !== urlLogin) {
            try {
                const { access_token: new_access_token } = await execRefreshToken()
                const config = error.response.config;
                config.headers['Authorization'] = 'Bearer ' + new_access_token;
                config.baseURL = BASE_API;
                return request(config);
            } catch (error) {
                deleteToken();
                window.location.href = AUTH_PATH + '/login';
            }
        }

        return Promise.reject(error);
    }
);

request.prototype.postForm = async (url, data) => {
    return await request.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export default request;

import React from 'react';
import { useSelector } from 'react-redux';

import { CNavItem, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';

import { AppSidebarNav } from './AppSidebarNav';

import useRoles from 'src/hooks/useRoles';
import { adminRoutes } from 'src/routes/AppRouter';

const AppSidebar = () => {
    const sidebarShow = useSelector((state) => state.layout.sidebarShow);
    const configs = useSelector((state) => state.layout.configs);

    const [checkRole] = useRoles();
    const menus = adminRoutes
        .filter((route) => {
            const roleStatus = route.roles ? checkRole(route.roles) : true;
            const showMenu = route.menu ? route.menu.isHiden !== true : true;

            return roleStatus && showMenu;
        })
        .map((route) => {
            const menu = route.menu || {};
            return {
                component: CNavItem,
                to: route.path,
                name: route.name,
                ...menu,
            };
        });
    return (
        <CSidebar
            position="fixed"
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                // dispatch(setSidebarShow(visible));
            }}
        >
            <CSidebarBrand className="d-none d-md-flex" to="/">
                <img src={configs.ADMIN_LOGO} alt="" style={{ height: 45, maxWidth: '100%', objectFit: 'contain' }} />
            </CSidebarBrand>
            <CSidebarNav>
                <AppSidebarNav items={menus} />
            </CSidebarNav>
        </CSidebar>
    );
};

export default React.memo(AppSidebar);

import moment from 'moment';
import { DATE_TIME_FORMAT, TIMEZONE } from 'src/constants/app.const';

export const show_date = (date) => {
    return moment(date).tz(TIMEZONE).format(DATE_TIME_FORMAT);
};

export const get_days_of_range = (start_date, end_date) => {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const days = [];

    // Iterate over the dates
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        // Format the current date as desired (e.g., 'YYYY-MM-DD')
        const formattedDate = currentDate.toISOString().split('T')[0];
        days.push(formattedDate);

        // Increment the current date by 1 day
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
};

import { CButton, CCard, CCardBody, CFormInput } from '@coreui/react';

import LiveStreamService from 'src/services/livestream.service';

import { useRef, useState } from 'react';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import DSelect from 'src/components/Form/DSelect';
import SelectField from 'src/components/Form/SelectField';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import { DOWNLOAD_STATUS_DOWNLOADED, DOWNLOAD_STATUSES, ROLES, STREAM_URLS } from 'src/constants';
import { show_date } from 'src/helpers/date';
import useAdmins from 'src/hooks/useAdmins';
import useRoles from 'src/hooks/useRoles';
import notify from 'src/utils/notify';
import { get_form_data, get_static_url } from 'src/helpers';
import EditButton from 'src/components/DataTable/EditButton';
import { type } from '@testing-library/user-event/dist/type';

const LiveStream = () => {
    const childRef = useRef();
    const [admins] = useAdmins();
    const [, isRole] = useRoles();
    const [isStarting, setIsStarting] = useState(false);
    const [isStopping, setIsStopping] = useState(false);

    const formSettings = [
        { label: 'Description', name: 'title', validate: 'string|nullable' },
        {
            label: 'Stream URL',
            name: 'stream_url',
            attributes: {
                placeholder: 'Stream URL',
            },
        },
        { label: 'Stream Key', name: 'stream_key', validate: 'string|required' },
        {
            label: 'Video ids',
            name: 'videos',
            attributes: {
                placeholder: '1,2,3',
            },
            format: ({ value }) => {
                const videos = Array.isArray(value) ? value : [];
                return videos.map((i) => i.id).join(',');
            },
        },
    ];

    const gridSettings = [
        { label: 'Id', column: 'id' },
        { label: 'Title', column: 'title' },
        {
            label: 'Stream URL',
            column: 'stream_url',
        },
        { label: 'Stream Key', column: 'stream_key' },
        {
            label: 'Live Status',
            column: 'is_live',
            render: ({ item }) => {
                return (
                    <div className="ring-container">
                        <div className="ringring"></div>
                        <div className={`circle ${item.is_live ? 'online' : 'offline'}`}></div>
                    </div>
                );
            },
        },

        {
            label: 'Video',
            column: 'download_status',
            render: ({ item }) => {
                return item.videos?.map((video) => {
                    return (
                        <div key={video.id} className="d-flex justify-content-center">
                            <a
                                target="_blank"
                                href={get_static_url(video.video_path)}
                                className="btn m-1 btn-sm btn-success"
                            >
                                Video {video.id}
                            </a>
                        </div>
                    );
                });
            },
        },
        { label: 'Create By', column: 'admin', render: ({ item }) => item.admin },

        { label: 'Create At', column: 'created_at', render: ({ item }) => show_date(item.created_at) },

        {
            label: 'Actions',
            render: ({ item, extraData }) => {
                return (
                    <div className="d-flex justify-content-center">
                        {item.is_live ? (
                            <CButton
                                className="btn m-1 btn-sm btn-danger"
                                disabled={extraData.isStopping}
                                onClick={() => handleStopLive(item)}
                            >
                                Stop live
                            </CButton>
                        ) : (
                            <>
                                <CButton
                                    className="btn m-1 btn-sm btn-success"
                                    onClick={() => handleStartLive(item)}
                                    disabled={extraData.isStarting}
                                >
                                    Start live
                                </CButton>
                                <EditButton onClick={() => childRef.current.onEdit(item)} />

                                <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    function handleStartLive(live) {
        if (!window.confirm('Are you sure?')) {
            return;
        }
        if (isStarting) {
            notify.warning('Start live is in progress');
            return;
        }
        setIsStarting(true);
        LiveStreamService.startLive(live.id)
            .then((data) => {
                if (data.status === 'error') {
                    notify.error(data.message);
                    return;
                }
                notify.success('Successful live broadcast');
                childRef.current.reload();
            })
            .catch((error) => {
                notify.response(error);
            })
            .finally(() => {
                setIsStarting(false);
            });
    }

    function handleDownload(live) {
        LiveStreamService.download(live.id).then((data) => {
            if (data.status === 'error') {
                notify.error(data.message);
                return;
            }
            notify.success('Download in progress');
            childRef.current.reload();
        });
    }

    function handleStopLive(live) {
        if (isStopping) {
            notify.warning('Slowly');
            return;
        }
        setIsStopping(true);
        LiveStreamService.stopLive(live.id)
            .then((res) => {
                console.log(res);

                notify.success('Stopped successfully');
                childRef.current.reload();
            })
            .finally(() => {
                setIsStopping(false);
            });
    }

    function onSubmitForm(e) {
        e.preventDefault();
        const formSearch = get_form_data(e);
        childRef.current.setQueryParams((pre) => ({ ...pre, ...formSearch, page: 1 }));
    }

    async function onSubmit(data, actions) {
        const videoIds = data.videos?.split(',').reduce((acc, cur) => {
            const number = Number(cur);
            if (number) {
                acc.push(number);
            }
            return acc;
        }, []);
        if (videoIds.length < 1 || videoIds.length > 100) {
            notify.warning('Maximum 100 videos allowed');
            return;
        }

        data.videos = videoIds;

        childRef.current.onSubmit(data, actions);
    }

    function gridHooks(event, data) {
        // console.log(event, data);
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <HasRole roles={[ROLES.ROLE_LEADER]}>
                            <div className="col-3">
                                <label htmlFor="">User</label>
                                <DSelect name="admin_id" options={admins} />
                            </div>
                        </HasRole>
                        <div className="col-3">
                            <label htmlFor="">Create At</label>
                            <CFormInput type="date" name="date"></CFormInput>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Live Status</label>
                            <DSelect
                                name="is_live"
                                options={[
                                    { label: 'Live status', value: '' },
                                    { label: 'Đang live', value: 1 },
                                    { label: 'Không live', value: 0 },
                                ]}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Tìm
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
            </CCard>

            <GridResource
                ref={childRef}
                moduleName="Live Stream"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={LiveStreamService}
                customSubmit={onSubmit}
                extraData={(isStarting, isStopping)}
                hook={gridHooks}
            />
        </>
    );
};
export default LiveStream;

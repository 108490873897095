import ImageField from 'src/components/Form/ImageField';
import InputField from 'src/components/Form/InputField';
import Json from 'src/components/Form/Json';
import Textarea from 'src/components/Form/Textarea';

export const formConfigs = ({ fields, data = {} }) => {
    const initialValues = {};
    const settings = fields.map((i) => {
        let component;
        initialValues[i.field] = data[i.field] ?? '';

        switch (i.type) {
            case 'image':
                component = ImageField;
                break;
            case 'textarea':
                component = Textarea;
                break;
            case 'json':
                component = Json;
                break;
            default:
                component = InputField;
                break;
        }
        return {
            label: i.name,
            name: i.field,
            component,
            col: i.col ?? 6,
            validate: i.validate,
            default: data[i.field] ?? '',
        };
    });

    return {
        settings,
    };
};

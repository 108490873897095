import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/assets/css/index.scss';
import 'react-datepicker/dist/react-datepicker.css';

import App from './App';
// import reportWebVitals from './reportWebVitals';

import 'src/plugins';

import store from './store';
import { Provider } from 'react-redux';
window.log = console.log;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

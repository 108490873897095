import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AdminService from 'src/services/admin.service';


const initialState = {
    leaders:[]
};

export const getLeaders = createAsyncThunk('admin/getLeaders', async (params, thunkAPI) => {
    try {
        return await AdminService.leaders();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
      
    },
    extraReducers: {
        [getLeaders.pending]: (state) => {
           
        },
        [getLeaders.fulfilled]: (state, { payload }) => {
            state.configs = payload.configs
        },
        [getLeaders.rejected]: (state) => {
        },
    },
});

const { reducer: adminReducer } = adminSlice;

export const { setSidebarShow } = adminSlice.actions;

export default adminReducer;

import request from 'src/utils/request';

const LiveStream = {
    async list(
        params = {
            page: 1,
            page_size: 20,
        }
    ) {
        const { data } = await request.get('/admin/livestream', { params });
        return data;
    },
    async show(id) {
        return await request.get(`/admin/livestream/${id}`);
    },
    async update(id, data) {
        return await request.put(`/admin/livestream/${id}`, data);
    },
    async create(data) {
        return await request.post(`/admin/livestream`, data);
    },
    async delete(id) {
        return await request.delete(`/admin/livestream/${id}`);
    },
    async startLive(id) {
        return await request.put(`/admin/livestream/${id}/start`);
    },

    async stopLive(id) {
        return await request.put(`/admin/livestream/${id}/stop`);
    },
    
    async download(id) {
        return await request.put(`/admin/livestream/${id}/download`);
    },


};

export default LiveStream;

import { CButton } from '@coreui/react';
import { FastField, Form, Formik } from 'formik';
import { getSettingForm } from 'src/helpers/form';
import useRoles from 'src/hooks/useRoles';

const BaseForm = ({ onSubmit, settings, isLoading = false, children = null, data = {}, type }) => {
    const { initialValues, validationSchema, newSettings, defaultData } = getSettingForm(settings, type);
    const [checkRole] = useRoles();
    const formData = {
        ...defaultData,
        ...data,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(formikProps) => {
                if (Object.keys(formData)) {
                    for (let i in formData) {
                        if (typeof initialValues[i] === 'undefined' && i !== 'id') {
                            continue;
                        }
                        initialValues[i] = formData[i];
                    }
                }
                const settingOnChange = newSettings.reduce((before, i) => {
                    if (i.roles && !checkRole(i.roles)) return before;
                    const current = i.render({ formikProps, i });
                    if (current) {
                        before.push(current);
                    }
                    if (typeof initialValues[i.name] !== 'undefined') {
                        initialValues[i.name] = i.format({
                            value: initialValues[i.name],
                            formData: formData,
                        });
                    }

                    return before;
                }, []);

                return (
                    <>
                        <Form className={'base-form row'} encType="multipart/form-data">
                            {settingOnChange.map((i, index) => {
                                return (
                                    <div key={index} className={`col-sm-${i.col} col-12`}>
                                        <FastField
                                            formikProps={formikProps}
                                            label={i.label}
                                            name={i.name}
                                            component={i.component}
                                            isRequired={i.isRequired}
                                            {...i.attributes}
                                        />
                                    </div>
                                );
                            })}
                            <div className="col-12 text-center mt-3">
                                {children ? (
                                    children
                                ) : (
                                    <CButton
                                        type="submit"
                                        color="primary"
                                        className="px-4"
                                        style={{ width: 200 }}
                                        disabled={isLoading}
                                    >
                                        Save
                                    </CButton>
                                )}
                            </div>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export default BaseForm;

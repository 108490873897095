import GridTable from 'src/components/GridTable';
import { WALLET_TYPE } from 'src/constants';
import { format_money } from 'src/helpers';
import { formatDate } from 'src/utils/date';

const WalletDetail = ({ data }) => {
    const list = data;

    const setting = [
        {
            label: 'Ngày tạo',
            column: 'date',
            render: ({ item }) => {
                return formatDate(item.created_at);
            },
        },

        {
            label: 'Money',
            column: 'money',
            render: ({ item }) => {
                return format_money(item.money);
            },
        },
        {
            label: 'Type',
            render: ({ item }) => {
                return WALLET_TYPE[item.type] ?? '';
            },
        },
    ];

    return (
        <>
            <GridTable setting={setting} data={{ list }} pagination={false} />
        </>
    );
};

export default WalletDetail;

import moment from 'moment';
import { useEffect, useState } from 'react';
import { get_days_of_range } from 'src/helpers/date';
import StatisticService from 'src/services/statistic.service';
import notify from 'src/utils/notify';
import { number_format } from 'src/utils/number';

const StatisticByDate = ({ queryParams }) => {
    const { start_date, end_date } = queryParams;

    const [data, setData] = useState({
        total: {},
        admins: {},
    });
    useEffect(() => {
        fetchData();
    }, [queryParams]);
    function fetchData() {
        StatisticService.byDate(queryParams)
            .then(({ data }) => {
                setData(data);
            })
            .catch(notify.response);
    }
    const current_date = moment();
    const days = get_days_of_range(start_date, end_date).filter((day) => {
        return moment(day).isBefore(current_date, 'day');
    });
    return (
        <>
            <div className="base-table">
                <table
                    className="table custom-datatable table-sticky"
                    style={{
                        tableLayout: 'fixed',
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: 100,
                                }}
                            >
                                Team
                            </th>
                            <th
                                style={{
                                    width: 100,
                                }}
                            >
                                Total
                            </th>
                            {days.map((i) => {
                                return (
                                    <th
                                        key={i}
                                        style={{
                                            width: 90,
                                        }}
                                    >
                                        {moment(i).format('DD/MM')}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(data.admins).map((admin_id) => {
                            const { sum: total_views = 0 } = data.total[admin_id];
                            return (
                                <tr key={admin_id}>
                                    <td>{data.admins[admin_id]}</td>
                                    <td>{number_format(total_views)}</td>
                                    {days.map((day) => {
                                        const views = data.total[admin_id][day] || 0;
                                        return (
                                            <td
                                                key={day}
                                                style={{
                                                    width: 100,
                                                }}
                                            >
                                                {number_format(views)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default StatisticByDate;

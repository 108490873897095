import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import moment from 'moment';
import { useState } from 'react';
import DSelect from 'src/components/Form/DSelect';
import NavTab from 'src/components/NavTab';
import HasRole from 'src/components/auth/HasRole';
import { ROLES } from 'src/constants';
import { DATE_FORMAT } from 'src/constants/app.const';
import useLeaders from 'src/hooks/useLeaders';
import notify from 'src/utils/notify';
import StatisticByAdmin from './components/StatisticByAdmin';
import StatisticByCountry from './components/StatisticByCountry';
import StatisticByDate from './components/StatisticByDate';
import useRoles from 'src/hooks/useRoles';

const Statistic = () => {
    const [queryParams, setQueryParams] = useState(() => {
        return {
            start_date: moment().startOf('month').format(DATE_FORMAT),
            end_date: moment().format(DATE_FORMAT),
        };
    });
    const [, isRole] = useRoles();
    const NAV_TABS = [
        { name: 'by_admin', label: 'Sort By User', component: StatisticByAdmin },
        { name: 'by_country', label: 'Sort By Countries', component: StatisticByCountry },
        !isRole(ROLES.ROLE_MEMBER) ? { name: 'by_date', label: 'Sort By Date', component: StatisticByDate } : undefined,
    ];
    const [leaders] = useLeaders();

    function onSubmitForm(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const start_date = formData.get('start_date');
        const end_date = formData.get('end_date');
        const leader_id = formData.get('leader_id');
        if (!start_date || !end_date) {
            notify.warning('Please select times ');
            return;
        }

        setQueryParams({ start_date, end_date, leader_id });
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardHeader>Statistical </CCardHeader>
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <HasRole roles={[ROLES.ROLE_SUPER]}>
                            <div className="col-3">
                                <label htmlFor="">Leader</label>
                                <DSelect name="leader_id" options={leaders}></DSelect>
                            </div>
                        </HasRole>
                        <div className="col-3">
                            <label htmlFor="">From</label>
                            <input
                                defaultValue={queryParams.start_date}
                                type="date"
                                className="form-control"
                                name="start_date"
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="">To</label>
                            <input
                                defaultValue={queryParams.end_date}
                                type="date"
                                className="form-control"
                                name="end_date"
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Search
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
                <CCardBody className="pt-0">
                    <NavTab data={{ queryParams }} tabs={NAV_TABS} defaultTab={0} />
                </CCardBody>
            </CCard>
        </>
    );
};

export default Statistic;

import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import LabelStatus from 'src/components/DataTable/LabelStatus';
import GridResource from 'src/components/GridResource';
import service from 'src/services/domain.service';
import notify from 'src/utils/notify';

const Domain = () => {
    const childRef = useRef();
    const formSettings = [{ label: 'Enter Domain or IP', name: 'name', validate: 'string|required' }];

    const configs = useSelector((state) => state.layout.configs);
    const gridSettings = [
        { label: 'Id', column: 'id' },
        { label: 'Domain', column: 'name' },
        { label: 'Create By', column: 'admin_name' },
        {
            label: 'Status',
            column: 'status',
            render: ({ item }) => {
                return <LabelStatus status={item.active} />;
            },
        },
        {
            label: 'Actions',
            render: ({ item }) => {
                return (
                    <div className="d-flex justify-content-center">
                        {/* <EditButton onClick={() => childRef.current.onEdit(item)} /> */}
                        <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                    </div>
                );
            },
        },
    ];
    function validateDomainOrIP(inputValue) {
        // Regular expression to match IP address (IPv4 or IPv6)
        const ipRegEx =
            /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)?(:\d+)?$/i;

        // Regular expression to match domain name
        const domainRegEx = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;

        if (ipRegEx.test(inputValue)) {
            // The input value is a valid IP address
            return true;
        } else if (domainRegEx.test(inputValue)) {
            // The input value is a valid domain name
            return true;
        } else {
            // The input value is not a valid IP address or domain name
            return false;
        }
    }

    async function onSubmit(data, actions) {
        const validate = validateDomainOrIP(data.name);
        if (validate) {
            childRef.current.onSubmit(data, actions);
        } else {
            notify.warning('Please enter domain or IP');
        }
    }

    return (
        <>
            {configs.NOTIFY_DOMAIN ? (
                <CCard className='mb-3'>
                    <CCardHeader>Notification</CCardHeader>
                    <CCardBody>{configs.NOTIFY_DOMAIN}</CCardBody>
                </CCard>
            ) : (
                ''
            )}
            <GridResource
                ref={childRef}
                moduleName="Domain"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={service}
                customSubmit={onSubmit}
            />
        </>
    );
};

export default Domain;

import storage from "src/core/storage";
import UserService from "src/services/user.service";

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const FAKE_ADMIN_KEY = 'super_data';

export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}
export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setToken({ access_token, refresh_token }) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
    return localStorage.setItem(TOKEN_KEY, access_token);
}

export function deleteToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    return localStorage.removeItem(TOKEN_KEY);
}

export function setFakeAdmin({ access_token, refresh_token }) {
    localStorage.setItem(
        FAKE_ADMIN_KEY,
        JSON.stringify({
            access_token: localStorage.getItem('access_token'),
            refresh_token: localStorage.getItem('refresh_token'),
        })
    );
    setToken({
        access_token,
        refresh_token,
    });
}
export function isFakeAdmin() {
    return !!localStorage.getItem(FAKE_ADMIN_KEY);
}

export function rollbackSuperToken() {
    const data = JSON.parse(localStorage.getItem(FAKE_ADMIN_KEY));
    setToken({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
    });
}
export function deleteFakeAdmin() {
    localStorage.removeItem(FAKE_ADMIN_KEY);
}


export function setIsRefreshingToken(status = true) {
    return storage.set('isRefreshingToken', status);
}

export async function execRefreshToken() {
    if (storage.get('isRefreshingToken')) {
        console.log('start waiting');
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (!storage.get('isRefreshingToken')) {

                    clearInterval(interval);
                    console.log('end waiting');
                    resolve({
                        access_token: getToken(),
                        refresh_token: getRefreshToken(),
                    });
                }
            }, 200);
        })
    }

    setIsRefreshingToken(true);
    try {
        const refresh_token = getRefreshToken();
        const { data } = await UserService.refreshToken(refresh_token);
        const tokenData = data.data;
        setToken({
            access_token: tokenData.access_token,
            refresh_token: tokenData.refresh_token
        });
        return tokenData;
    }
    finally {
        setIsRefreshingToken(false);
    }

}
import { useSelector } from 'react-redux';
import { ROLES } from 'src/constants';
import { typeOf } from 'src/utils/common';

const useRoles = () => {
    const userRole = useSelector((state) => state.user.role);

    const isRole = (roles) => {
        roles = typeOf(roles) === 'array' ? roles : [];
        return roles.indexOf(userRole) !== -1;
    };
    const checkRole = (roles) => {
        if (userRole === ROLES.ROLE_SUPER) return true;
        return isRole(roles);
    };

    return [checkRole, isRole];
};

export default useRoles;

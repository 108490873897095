import { CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';

const LoadingWrap = ({ children, isLoading, placeholder = false, fadeIn = false }) => {
    if (isLoading) {
        if (placeholder) {
            return (
                <>
                    <p className="card-text placeholder-glow p-3">
                        <span className="placeholder col-7"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-4"></span>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder col-8"></span>
                    </p>
                </>
            );
        }
        return (
            <>
                <CSpinner component="span" aria-hidden="true" />
            </>
        );
    }
    if (!fadeIn) {
        return children;
    }

    return <div className="fadeIn">{children}</div>;
};

LoadingWrap.propTypes = {
    isLoading: PropTypes.bool,
};
LoadingWrap.defaultProps = {
    isLoading: false,
};

export default LoadingWrap;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './routes/AppRouter';

export default function App() {
    return (
        <>
            <BrowserRouter>
                <React.Suspense fallback={<div>loading ...</div>}>
                    <AppRouter />
                </React.Suspense>
            </BrowserRouter>
        </>
    );
}
